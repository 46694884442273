import React from 'react'
import {graphql} from "gatsby"
// import { Helmet } from 'react-helmet'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Banner from '../components/case-study-banner'
import CaseColumn from '../components/case-column'
import FreeTrial from '../components/free-trial'
import Quote from '../components/quote'


import content from '../helpers/contentChecker'

const CaseStudy = ({ data }) => {

  const supportDetails = {
    bgColor: 'blue',
    quotes: [
      {
        heading: '',
        profile:  data.gcms.caseStudy.testimonial.headshot !== null && content.nullChecker(data.gcms.caseStudy.testimonial.headshot.url), 
        quote: data.gcms.caseStudy.testimonial !== null && content.nullChecker(data.gcms.caseStudy.testimonial.quotation),
        parag: [
          {
            title: "The Results",
            text: data.gcms.caseStudy.theResults !== null && content.nullChecker(data.gcms.caseStudy.theResults)
          }
        ],
      }
    ]
  }
    
  const trialContent = {
    title: "Start your free trial of Billsby today",
    description: "When you try Billsby for yourself, you'll make your first $5,000 of subscription revenue on us - and you don't even need a credit card to get started. The best way to get to know Billsby is to try it for yourself.",
    image: require('../images/free-trial.svg'),
    svg: true,
    imageName: 'free-trial image',
    buttons: [
      {
        url: 'https://app.billsby.com/registration',
        isExternal: true,
        buttonColor: 'orange',
        buttonText: 'Get started with your free trial account'
      },
      {
        scheduleButton: true,
        buttonColor: 'black'
      }
    ]
  }

  return (
    <>
      <Layout 
        className="case-study"
        isHeaderTransparent={false}
      >
        <SEO 
          title={content.nullChecker(data.gcms.caseStudy.companyName) + " uses Billsby subscription billing software to power their recurring payments"}
          description="Rely on Billsby for easy and accurate recurring payments. The most powerful, customizable and easy-to-integrate subscription billing software."
          url={"https://www.billsby.com/"+content.nullChecker(data.gcms.caseStudy.slug)}
        />

        <Banner data={{
          companyName: content.nullChecker(data.gcms.caseStudy.companyName),
          companyDescription: content.nullChecker(data.gcms.caseStudy.companyDescription),
          companyLogo: data.gcms.caseStudy.companyLogo !== null && content.nullChecker(data.gcms.caseStudy.companyLogo.url),
          companyUrl: content.nullChecker(data.gcms.caseStudy.companyUrl), 
          headline: content.nullChecker(data.gcms.caseStudy.headline),
          subheading: content.nullChecker(data.gcms.caseStudy.subheading)
        }} />

        <div className="prob-solution-wrapper">
          { 
            content.nullChecker(data.gcms.caseStudy.theProblem) !== '' &&
            <CaseColumn data={{
              title: 'The Problem',
              align: 'left',
              markdown: content.nullChecker(data.gcms.caseStudy.theProblem),
              image: data.gcms.caseStudy.theProblemImage !== null && content.nullChecker(data.gcms.caseStudy.theProblemImage.url),
            }} />
          }

          { 
            content.nullChecker(data.gcms.caseStudy.theSolution) !== '' &&
            <CaseColumn data={{
              title: 'The Solution',
              align: 'right',
              markdown: content.nullChecker(data.gcms.caseStudy.theSolution),
              image: data.gcms.caseStudy.theSolutionImage !== null && content.nullChecker(data.gcms.caseStudy.theSolutionImage.url),
            }} />
          }
          
        </div>
        
        <Quote content={supportDetails} />

        <FreeTrial content={trialContent}/>
      </Layout>
      
      {/* <Helmet>
        <title>{content.nullChecker(data.gcms.caseStudy.companyName)}  | Billsby</title>
      </Helmet> */}
    </>
  )
}

export default CaseStudy

export const query = graphql`
query CaseStudyTemplate($caseStudyId: ID!) {
  gcms {
    caseStudy(where: {id: $caseStudyId}) {
        companyName,
        companyUrl, 
        headline,
        subheading,
        slug,
        companyDescription,
        companyLogo {
          url
        },
        theProblem,
        theProblemImage {
          url
        },
        theSolution,
        theSolutionImage {
          url
        },
        theResults,
        testimonial {
          quotation
          
          headshot {
            url
          }
        }
    }
  }
}
`