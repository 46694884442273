import React from 'react'
import classnames from 'classnames'
import { Remarkable } from 'remarkable';
import RemarkableReactRenderer from 'remarkable-react';
import GraphImg from "graphcms-image";


const md = new Remarkable({
  html:         false,        // Enable HTML tags in source
  xhtmlOut:     false,        // Use '/' to close single tags (<br />)
  breaks:       true,        // Convert '\n' in paragraphs into <br>
});
md.renderer = new RemarkableReactRenderer();


const CaseColumn = ({ data }) => {
  return (
    <div className="container case-column-wrapper">
      {
        data.align === 'right' && data.image !== '' && data.image !== undefined && data.image !== null &&
        <div className="image-block alignRight">
          <img src={data.image} alt={data.image ? data.image : ''}/>
          <GraphImg image={data.image} maxWidth={500} />
        </div>
      }
      <div className={
        classnames('text-block', {
          alignLeft : data.align === 'left',
          alignRight : data.align === 'right'
        })
      }>
        <h2 className="heading-2"> {data.title} </h2>
        <p id="content" className="markdown-text"> {md.render(data.markdown)} </p>
      </div>
      {
        data.align === 'left' && data.image !== '' && data.image !== undefined && data.image !== null &&
        <div className="image-block alignLeft">
          <img src={data.image} alt={data.image ? data.image : ''} />
          <GraphImg image={data.image} maxWidth={500} />
        </div>
      }
    </div>
  )
}

export default CaseColumn
